/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
@import "../../mixins/mixins.scss";

.flickity-enabled {
  position: relative;
  display: grid;
  grid-row-gap: var(--spacing-SM);
}

.flickity-enabled:focus { outline: none; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
  cursor: url("/img/global/carousel2.svg") 38 38, auto;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
    cursor: url("/img/global/carousel.svg") 25 25, auto;
}

/* ---- flickity-button ---- */

.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}

.flickity-button:hover {
  background: white;
  cursor: pointer;
}

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}

.flickity-button:active {
  opacity: 0.6;
}

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}

.flickity-button-icon {
  fill: currentColor;
}

/* ---- previous/next buttons ---- */

.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
}

.flickity-prev-next-button.previous { left: 10px; }
.flickity-prev-next-button.next { right: 10px; }
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}

// .flickity-prev-next-button .flickity-button-icon {
//   position: absolute;
//   left: 50%;
//   top: 50%;
//   transform: translate(-50%, -50%);
// }

/* ---- page dots ---- */

.flickity-page-dots {
  // position: absolute;
  width: 100%;
  bottom: -45px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
  @include smMax {
    bottom: -35px;
  }
}

.flickity-rtl .flickity-page-dots { direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}

.flickity-page-dots .dot.is-selected {
  opacity: 1;
}




/*
____ _  _ ____ ___ ____ _  _
|    |  | [__   |  |  | |\/|
|___ |__| ___]  |  |__| |  |
*/
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 90vh !important;
  @include portrait {
    height: 45vh !important;
  }
  @include smMax {
    height: 30vh !important;
  }
}
.carouselSection, .carouselSectionSlim {
  grid-column: var(--gridSection-LG);
  position: relative;
}
.carousel-cell, .carousel-cell img  {
  width: 100%;
  height: 90vh;
  object-fit: cover;
  @include portrait {
    height: 45vh;
  }
  @include smMax {
    height: 30vh;
  }
}
.flickity-page-dots .dot {
  height: .125rem;
  width: 5rem;
  margin: 0;
  border-radius: 0;
  background: #161616;
  opacity: .15;
  padding: 0;
  @include smMax {
    width: 50px;
  }
}
.flickity-button, .flickity-button:hover {
  background: transparent;
}
.flickity-prev-next-button {
  width: 10vh;
  height: 10vh;
  z-index: 2;
  top: 49.5vw;
  border-radius: 0;
  @include touch {
    display: none
  }
  @include smMax {
    display: none
  }
}
.flickity-prev-next-button.next {
  right: 1.8%;
  top: 23.5vw;
}
.flickity-prev-next-button.previous {
  left: 1.8%;
  top: 20.7vw;
}
.flickity-button-icon {
  fill: #161616;
  transform: rotate(-90deg);
}
.flickity-button-icon:hover {
  fill:#161616;
}
.flickity-button:disabled {
  opacity: .1;
}
#prevBtn, #nextBtn {
  height: 10vh;
  width: 10vh;
  position: absolute;
  top: 22vw;
  z-index: 1;
  box-shadow: 0 12px 50px rgba(0,0,0,.15);
  @include touch {
    display: none
  }
  @include smMax {
    display: none
  }
}
#prevBtn {
  left: 0%;
}
#nextBtn {
  right: 0%;
}
.carouselSectionSlim {
  #prevBtn, #nextBtn, .flickity-prev-next-button {
    display: none;
  }
  .flickity-viewport {
    height: 70vh !important;
    // background-color: #F2F5F5;
    @include portrait {
      height: 45vh !important;
    }
    @include smMax {
      height: 40vh !important;

    }
  }
  .carousel-cell, .carousel-cell img {
    width: 65%;
    height: 70vh;
    margin-right: 40px;
    @include portrait {
      height: 45vh;
    }
    @include smMax {
      height: 40vh;
      width: 85%;
      margin-right: 20px;

    }
  }
  .flickity-page-dots {
    display: none;
  }
}


#prevBtn, #nextBtn, .flickity-prev-next-button {
  display: none;
}
