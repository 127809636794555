@import 'src/mixins/mixins.scss';

canvas {
    background-color: #161616;
    overflow: hidden;
    position: fixed;
}
.bg-black {
    background-color: #161616 !important;
}
.color-black {
    color: #161616 !important;
}
#background {
    background: rgba(22, 22, 22, 0);
}
.difference {
    mix-blend-mode: difference;
}
.no-blend {
    mix-blend-mode: unset !important;
}

.menuBtn {
    pointer-events: all;
}

#homeGrid {
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    background: white;
    overflow-x: hidden;
    padding-left: 5%;
    padding-top: calc(50vh / 1.5);
    grid-row-gap: var(--space-between-sections);
    @include portrait {
        padding-left: 0;
        padding-top: calc(50vh / 1.4);
    }
    @include smMax {
        padding-left: 0;
        padding-top: calc(50vh / 2.25);
    }
    #nextProjectTrigger {
        padding-bottom: var(--next-project-button-height);
        margin-top: var(--space-between-sections);
    }
}
