@import '../../mixins/mixins.scss';

.footer {
    grid-column: var(--gridSection-SM);
    display: grid;
    grid-row-gap: var(--spacing-LG);
}

.credits {
    display: flex;
    width: 100%;
    grid-gap: var(--spacing-SM);
    p {
        font-size: var(--font-lvl-1);
        line-height: 130%;
        color: rgba(22, 22, 22, 0.6);
        width: 50%;
    }
    b {
        color: rgba(22, 22, 22, 1);
        font-weight: 400;
    }
    @include smMax {
        p {
            width: 50%;
            text-align: left;
            float: right;
        }
        b {
            text-align: left;
            float: left;
        }
    }
}

.award {
    width: 25%;
    &:nth-child(3n + 1) {
        padding: 0 5% 10% 0;
    }
    &:nth-child(3n + 2) {
        padding: 0 5% 10% 5%;
    }
    &:nth-child(3n + 3) {
        padding: 0 0% 10% 5%;
    }
    img {
        width: 100%;
    }
}

@media only screen and (max-width: 1112px) {
    #grid a {
        grid-column: 5/12;
    }
}
