@import "../../mixins/mixins.scss";
#menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 55%;
  background-color: white;
  display: none;
  z-index: 3;
  overflow-x: hidden;
  overflow-y: auto;
  // pointer-events: none;
  @include portrait {
    width: 85%;
  }
  @include smMax {
    width: 100%;
  }
}
.menuItems {
  display: grid;
  padding: var(--padding-sides) 0 var(--spacing-LG) 13.5vw;
  overflow-x: hidden;
  @include portrait {
    padding-left: 19vw;
  }
  @include smMax {
    padding-left: 22vw;
  }
  & p {
     font-size: var(--font-lvl-2);
     @include xxlMin {
      font-size: var(--font-lvl-3);    
      }
    }
    &:hover > li {
      opacity: .3;
    }
    & li {
      pointer-events: auto;
    }
    & li:hover {
      opacity: 1;
    }
}
.menuProject {
  padding: 0;
  p {
    padding-bottom: var(--spacing-SM);
    margin-left: 0;
  }
  li {
    display: block;
    transition: opacity .3s ease;
    cursor: pointer;
    width: 41.5vw;
    @include portrait {
      width: 85vw;
    }
    @include smMax {
      width: 95vw;
    }
  }
  &:hover > li {
    opacity: .3;
  }
  li:hover {
    opacity: 1;
  }
}
.menuOverlay {
  position: fixed;
  display: none;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,.5);
  z-index: 2;
  cursor: pointer;
}
.menuBackground {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 55%;
  pointer-events: none;
  z-index: -1;
  transition: all .3s ease;
  @include smMax {
    width: 85%;
  }
  @include portrait {
    width: 100%;
  }
  @include touch {
    transition: all 0s ease;
  }
}
li.home, li.contact {
  position: fixed;
  line-height: 100%;
  transform: rotate(-90deg);
  left: var(--padding-sides);
  width: auto;
  transform-origin: top left;
  opacity: 1 !important;
  @include smMin {
    top: 10rem;
    font-size: var(--font-lvl-3);
    margin-left: -.25rem;
  }
  @include lgMin {
    top: 13rem;
    font-size: var(--font-lvl-4);
  }
  @include xxlMin {
    top: 15rem;
    font-size: var(--font-lvl-5);
  }
}
li.contact {
  top: 35%;
  @include portrait {
    top: 370px;
  }
  @include smMax {
    top: 250px;
  }
}

#progressBar div {
  transition: top .2s ease-in-out !important;

}
.hide-progress-bar {
  top: -1vh !important;
}

.homeNav {
  // height: 0 !important;
  position: absolute;
  top: 0;
}