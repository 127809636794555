
@mixin portrait {
  @media (orientation: portrait) {
    @content;
  }
}
@mixin touch {
  @media (hover: none) {
    @content;
  }
}
@mixin landscape {
  @media (orientation: landscape) {
    @content;
  }
}

@mixin smMin {
  @media screen and (min-width: 0px) {
    @content;
  }
}
@mixin smMax {
  @media screen and (max-width: 767px) {
    @content;
  }
}
@mixin mdMin {
  @media screen and (min-width: 768px) {
    @content;
  }
}
@mixin mdMax {
  @media screen and (max-width: 1023px) {
    @content;
  }
}
@mixin lgMin {
  @media screen and (min-width: 1024px) {
    @content;
  }
}
@mixin lgMax {
  @media screen and (max-width: 1919px) {
    @content;
  }
}
@mixin xlMin {
  @media screen and (min-width: 1920px) {
    @content;
  }
}
@mixin xlMax {
  @media screen and (max-width: 2559px) {
    @content;
  }
}
@mixin xxlMin {
  @media screen and (min-width: 2560px) {
    @content;
  }
}