@import 'src/mixins/mixins.scss';

#scrollDown {
    position: fixed;
    right: var(--padding-sides);
    bottom: var(--padding-sides);
    width: 7%;
    max-width: 11rem;
    background: unset;
    border-radius: 100rem;
    border: 0.5rem solid rgba(255, 255, 255, 0);
    @include portrait {
        width: 11%;
    }
    @include smMax {
        width: 20%;
    }
}
