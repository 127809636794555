@import 'mixins/mixins.scss';

// fonts
@font-face {
    font-family: 'Gt Eesti Display Light';
    src: url(/fonts/GT-Eesti-Pro-Display-Light.woff2);
}
@font-face {
    font-family: 'Eksell';
    src: url(/fonts/eksell.woff2);
}

// GLOBAL VARIABLES
:root {
    --projectColor: #fff;
    --nextProjectColor: #fff;
    // Project Colors
    --mothersday: #db5973;
    --rexyrace: #9bc4b6;
    --mbj: #ea872c;
    --signature: #cda479;
    --lifecoach: #febd28;
    --scenestealers: #6291a3;
    --isetan: #f0afa7;
    --newyorkdrive: #ff00ff;
    --rainbowhockey: #f5e500;

    // base font size
    @include smMin {
        font-size: 16px;
    }
    @include mdMin {
        font-size: 18px;
    }
    @include xlMin {
        font-size: 20px;
    }
    @include xxlMin {
        font-size: 22px;
    }

    // type scale
    --font-lvl-0: 0.8rem;
    --font-lvl-1: 1rem;
    --font-lvl-2: 1.25rem;
    --font-lvl-3: 1.563rem;
    --font-lvl-4: 1.953rem;
    --font-lvl-5: 2.441rem;
    --font-lvl-6: 3.052rem;
    --font-lvl-7: 3.815rem;

    // max-widths
    --maxWidth-SM: 900px;
    --maxWidth-MD: 1920px;

    // spacing
    --spacing-XS: 0.5rem;
    --spacing-SM: 1rem;
    --spacing-MD: 2rem;
    --spacing-LG: 2rem;
    --spacing-XL: 4rem;
    --padding-sides: var(--spacing-LG);
    @include lgMin {
        --spacing-LG: 4rem;
        --spacing-XL: 8rem;
    }

    // grid column sizes
    --gridSection-SM: 5 / 12;
    --gridSection-MD: 4 / 13;
    --gridSection-LG: 1 / 16;
    @media only screen and (max-width: 1112px) {
        --gridSection-SM: 4 / 13;
        --gridSection-MD: 3 / 14;
    }
    @include smMax {
        --gridSection-SM: 3 / 14;
    }

    --nav-height: 2rem;
    --space-above-hero: 0rem;
    --space-between-sections: var(--spacing-XL);
    --next-project-button-height: 14rem;
    // --next-project-button-height: calc(
    //     var(--space-between-sections) + var(--space-above-hero) + var(--padding-sides)
    // );
    @include mdMin {
        --next-project-button-height: 20rem;
    }
    @include lgMin {
        --space-above-hero: var(--spacing-SM);
        // --next-project-button-height: 20rem;
    }
    @include xlMin {
        --space-above-hero: var(--spacing-LG);
        // --next-project-button-height: 20rem;
    }
    @include xxlMin {
        --space-above-hero: var(--spacing-XL);
        --next-project-button-height: 32rem;
    }
}
/* 2020 */
.mothersday:hover ~ .menuBackground,
#nextProject.mothersday,
span.mothersday {
    background-color: var(--mothersday);
}
/* 2019 */
.rexyrace:hover ~ .menuBackground,
#nextProject.rexyrace,
span.rexyrace {
    background-color: var(--rexyrace);
}
.mbj:hover ~ .menuBackground,
#nextProject.mbj,
span.mbj {
    background-color: var(--mbj);
}
.signature:hover ~ .menuBackground,
#nextProject.signature,
span.signature {
    background-color: var(--signature);
}
.isetan:hover ~ .menuBackground,
#nextProject.isetan,
span.isetan {
    background-color: var(--isetan);
}
/* 2018 */
.lifecoach:hover ~ .menuBackground,
#nextProject.lifecoach,
span.lifecoach {
    background-color: var(--lifecoach);
}
.scenestealers:hover ~ .menuBackground,
#nextProject.scenestealers,
span.scenestealers {
    background-color: var(--scenestealers);
}
/* 2017 */
.newyorkdrive:hover ~ .menuBackground,
#nextProject.newyorkdrive,
span.newyorkdrive {
    background-color: var(--newyorkdrive);
    background-image: linear-gradient(0deg, #7069ff 0%, #ff00ff 100%);
}
.rainbowhockey:hover ~ .menuBackground,
#nextProject.rainbowhockey,
span.rainbowhockey {
    background-color: var(--rainbowhockey);
    background-image: linear-gradient(
        45deg,
        #ffe300 0%,
        #00ff62 32%,
        #00bbff 60%,
        #ff00ff 100%
    );
}

.home:hover ~ .menuBackground {
    background-color: #fff;
}

#blockColorblindContent {
    display: none;
}

/*
____ _    ____ ___  ____ _
| __ |    |  | |__] |__| |
|__] |___ |__| |__] |  | |___
*/

*,
html,
body {
    margin: 0;
    overscroll-behavior: none;
}
body {
    cursor: auto !important;
}
#header,
h3,
li {
    font-family: 'Eksell';
    color: rgba(22, 22, 22, 1);
    font-weight: normal;
    text-decoration: none;
    font-size: 6vw;
    line-height: 110%;
    @include portrait {
        font-size: 9vw;
    }
    @include smMax {
        font-size: 10.5vw;
    }
}
#header {
    line-height: 100%;
}
h1,
h2,
p,
a {
    font-family: 'Gt Eesti Display Light', sans-serif;
    text-decoration: none;
    color: rgba(22, 22, 22, 0.95);
    font-weight: 400;
    margin: 0;
}
h1,
a {
    font-size: var(--font-lvl-2);
    line-height: 160%;
}
a {
    white-space: nowrap;
}
p {
    font-size: var(--font-lvl-1);
    line-height: 160%;
    color: rgba(22, 22, 22, 0.75);
}
a {
    text-align: center;
    color: rgba(22, 22, 22, 0.8);
    & span {
        // underline
        border-bottom: 2px solid rgba(22, 22, 22, 0.5);
        padding-bottom: 3px;
        transition: all 0.3s ease;
    }
    & span:hover {
        border-color: rgba(22, 22, 22, 0.25);
        color: rgba(22, 22, 22, 0.5);
        transition: all 0.3s ease;
    }
}
li {
    font-size: 3.8vw;
    line-height: 130%;
    text-align: left;
    list-style: none;
    padding: 0;
    @include portrait {
        font-size: 6.5vw;
        width: 85vw;
    }
    @include smMax {
        font-size: 8vw;
        line-height: 150%;
        width: 100vw;
    }
}

p,
#introCopy {
    max-width: var(--maxWidth-SM);
    margin: auto;
}

// mobile/desktop only
.heroDesktop,
.screenDesktop {
    display: block;
    @include smMax {
        display: none;
    }
}
.heroMobile,
.screenMobile {
    display: none;
    @include smMax {
        display: block;
    }
}
.mobileOnly {
    display: none;
    @include smMax {
        display: block;
    }
}
.desktopOnly {
    display: block;
    @include smMax {
        display: none;
    }
}

// scrolling hero
#AR #screenMask {
    margin-top: -2.5vh;
}
#AR #device,
#AR #screenMask {
    @include mdMin {
        opacity: 1 !important;
        visibility: visible !important;
    }
}
#AR #screenMask {
    @include mdMin {
        height: 0;
    }
}
#AR #screen {
    @include smMax {
        top: 3%;
    }
}

// 2 Column layout shared styles
#twoColumnGrid {
    display: grid;
    grid-row-gap: var(--space-between-sections);
    .section {
        width: 100%;
        display: grid;
        grid-row-gap: var(--spacing-MD);
        @include lgMin {
            grid-template-columns: 40% 1fr;
            grid-column-gap: var(--spacing-LG);
        }
    }
    .column {
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        grid-row-gap: var(--spacing-SM);
        p {
            margin: 0;
        }
        h1 {
            font-family: 'Eksell';
            font-size: var(--font-lvl-6);
            line-height: 100%;
            width: 100%;
            @include xxlMin {
                font-size: 2vw;
            }
        }
    }

    .row {
        width: 100%;
        display: grid;
        grid-row-gap: var(--spacing-XS);
        p {
            font-size: var(--font-lvl-1);
            line-height: 130%;
            color: rgba(22, 22, 22, 1);
        }
        b {
            font-family: 'Eksell', serif;
            font-size: var(--font-lvl-7);
            line-height: 100%;
            @include xxlMin {
                font-size: 4vw;
            }
            @include smMax {
                font-size: var(--font-lvl-5);
                width: 50%;
                text-align: left;
            }
        }
    }
}


#nextProject {
    width: 100%;
    text-align: center;
    line-height: 1rem;
    height: 0;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    overflow: hidden;
    pointer-events: none;
    #wrapper {
        h3 {
            line-height: 100%;
        }
        position: absolute;
        left: 50%;
        width: 100%;
        transform: translateX(-50%);
        transition: margin-top 0.3s ease;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        // grid-gap: var(--spacing-SM);
        &:hover {
           margin-top: -0.5rem;
        }
        p {
            font-size: var(--font-lvl-2);
            line-height: 300%;
            height: 4rem;
            margin: 0 auto;
        }
        #nextProjectLabel {

        }
    }
}
