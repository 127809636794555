@import '../../mixins/mixins.scss';

#navGrid {
    display: grid;
    grid-template-columns: repeat(15, 1fr);
}
#nav {
    grid-column: 1/16;
    height: var(--nav-height);
    margin: var(--padding-sides) var(--padding-sides);
    @include smMax {
    }
}
.navItems {
    position: relative;
    height: inherit;
}
.title {
    position: absolute;
    left: 50%;
    top: 0;
    text-align: center;
    transform: translateX(-50%);
    width: 70%;
}
.year {
    top: 0;
    position: absolute;
    right: 0;
    text-align: right;
}
.menuBtn {
    z-index: 4;
    cursor: pointer;
    position: fixed;
    & input {
        position: absolute;
        cursor: pointer;
        opacity: 0;
        z-index: 5;
        height: 4rem;
        width: 4rem;
        left: calc(4rem * -.33);
        top: calc(4rem * -.33);
        // left: -1.25rem;
        // top: -0.75rem;   
       }
    .burger {
        position: absolute;
        width: 2rem;
        height: 1.24rem;
        margin-top: calc(0.62rem * 0.75);
        transform: scale(0.75) translateX(-0.35rem);
        @include smMax {
            transform: scale(0.6) translateX(-0.7rem);
            margin-top: calc(0.62rem * 0.6);
        }
        @include xxlMin {
            transform: scale(1) translateX(0rem);
            margin-top: calc(0.62rem * 1);
        }
        & span {
            position: absolute;
            background-color: #161616;
            width: 2rem;
            height: 0.12rem;
            left: 0;
            top: 0;
            transform-origin: 14.5% 40%;
            transition: transform 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
        }
    }

    & .burger span:nth-child(2) {
        top: 1rem;
        @include smMax {
            //  top: 14px;
        }
    }
    // & input:checked ~ .burger span {
    //   transform: rotate(45deg) translate(-2px, 1px);
    // }
    // & input:checked ~ .burger span:nth-child(2) {
    //   transform: rotate(-45deg) translate(-2px, -0.5px);
    // }
    & input:checked ~ .burger span {
        transform: rotate(45deg);
    }
    & input:checked ~ .burger span:nth-child(2) {
        transform: rotate(-45deg);
    }
}
